body {
  font-family: 'Gaegu', 'cursive';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
}

body {
  bbackground: url('/assets/texture_canvas_white.png') repeat;
  bbackground-attachment: fixed;
  color: white;
  background: url('/assets/bg_space_b@2x.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

nav span {
  margin-right: 20px;
}

nav {
  text-align: center;
  color: #121212;
  padding: 20px;
  height: 80px;
  font-size: 1rem;
}

nav a {
  color: #ffffff;
}

.App {
  ttext-align: center;
}

.explore:after {
  background-image: url('/assets/map@2x.png');
  background-color: #127091;
  background-size: contain;
  background-position: center bottom;
  background-attachment: fixed;
  background-repeat: no-repeat;
  opacity: .95;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.card {
  background-color: rgba(255,255,255,0.06) !important;
}

.card-body {
  text-align: left;
}

a, a:hover {
  color: white;
}

.text-muted {
  color: #FEAF00 !important;
}

.MuiRating-root {
  font-size: 1rem !important;
}

.container {
  margin-top: 3rem;
  margin-bottom: 5rem;
}